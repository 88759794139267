<template>
  <v-card flat>
    <b-row>
      <b-col :md="12">
        <b-form-group
          id="name-group"
          v-bind:label="$t('FORM_LABELS.NAME')"
          label-for="name-input"
        >
          <FormInput
            id="name-input"
            v-model="form.name"
            type="text"
            name="name"
            :i18n="{
              label: 'FORM_LABELS.NAME',
              placeholder: $t('FORM_LABELS.NAME')
            }"
            :validateState="validateState('name')"
          ></FormInput>
        </b-form-group>
      </b-col>
    </b-row>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormInput
  },
  props: {
    form: Object
  },
  data() {
    return {
      errors: {}
    };
  },
  validations() {
    const tmpValidationObject = {
      form: {
        name: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
